import { callSocket } from "shared/actions/socketAction";
import { socketActions, socketSubActions } from "socket/Websocket";
import { ShowAlert, showSwal } from "shared/actions/alert";
import { textConst, textLabels } from "shared/constants/const";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { errorToDisplay, formatDate, getDobFormat, unformatNumber } from "shared/actions/helpers";
import { store } from "redux/store";
import { savenorthwelluserobj, saveusercredentials } from "redux/actions/auth.action";
import { getToken } from "actions/index";
import { nameRegex } from "shared/actions/helpers";
import { loginConst } from "shared/constants/loginConst";
// import { initializePendo } from "shared/actions/thirdPartyVendors";
import { fetchQuery } from "shared/actions/fetchQueryAction";
import { GET_USER_DATA } from "actions/graphqlActions/userDataQuery";

export async function getPatientBanner(callback = () => {},err=()=>{}) {
   let token = await getToken();
   const obj = { Authorization: token, action: socketActions.onboarding, subAction: socketSubActions.getBanners };
   callSocket(obj, (data) => {
      callback(data.data);
   },()=>{err()});
}

export const checkUserExistsSocial = (token, callback, error = () => {}) => {
   const obj = {
      action: socketActions.auth,
      subAction: socketSubActions.checkUser,
      token: token,
   };
   callSocket(
      obj,
      (res) => {
         callback(res);
      },
      (err) => {
         error();
      }
   );
};

export const updateUser = (id, callback = () => {}, initialize = true) => {
   fetchQuery(
      GET_USER_DATA,
      {},
      (res) => {
         let profileData = res.data?.getProfile;
         const enterpriseName = profileData?.enterpriseInfo?.name || "";
         let redux_store = store.getState();
         let userCredentials = redux_store.auth.userCredentials;
         let updatedUserObject = {
            ...userCredentials,
            user: {
               ...userCredentials.user,
               name: profileData?.name?.fullName,
               initials: profileData?.name?.initials,
               colorCode: profileData?.colorCode,
               mobileNo: profileData?.contactInformation?.mobileNumber,
               email: profileData?.contactInformation?.email,
               enterpriseName: enterpriseName,
               dob: profileData?.dob,
            },
         };
         callback(updatedUserObject.user);
         // if (initialize) initializePendo(updatedUserObject);
         store.dispatch(saveusercredentials(updatedUserObject));
      },
      (err) => {
         let msg = errorToDisplay(err);
         ShowAlert(msg, "error");
      }
   );
};

export const resendOtpSignUp = (key, callback) => {
   var obj = {
      action: socketActions.onboarding,
      subAction: socketSubActions.resendOTP,
      key: key,
   };
   callSocket(
      obj,
      (res) => {
         ShowAlert(res.settings.message);
         callback();
      },
      (err) => {
         ShowAlert(err.settings.message, textConst.error);
      }
   );
};

export const resendOtpLoginMain = (isPhone, email, phone, callback) => {
   const phoneNumberWithoutFormat = unformatNumber(phone);
   let sendOtp = {};
   if (isPhone) {
      sendOtp = {
         action: socketActions.auth,
         subAction: socketSubActions.authUserPhone,
         mobileNo: "+" + phoneNumberWithoutFormat,
      };
   } else {
      sendOtp = {
         action: socketActions.auth,
         subAction: socketSubActions.authUserEmail,
         emailId: email,
      };
   }

   callSocket(
      sendOtp,
      (res) => {
         ShowAlert(res.settings.message);
         callback();
      },
      (err) => {
         ShowAlert(err.settings.message, textConst.error);
         callback();
      }
   );
};

export const socialLoginUser = (token, dob, navigate, callback) => {
   let sendOtp = {};
   sendOtp = {
      action: socketActions.auth,
      subAction: socketSubActions.validatePatient,
      firebaseToken: token,
      enteredDob: formatDate(dob, true),
   };

   callSocket(
      sendOtp,
      (res) => {
         firebase
            .auth()
            .signInWithCustomToken(res.data.customToken)
            .then((storeDataRedux) => {
               let userData = { user: res.data.userData };
               store.dispatch(savenorthwelluserobj(JSON.parse(JSON.stringify(storeDataRedux))));
               store.dispatch(saveusercredentials(userData));
               navigate("/");
            })
            .catch((error) => {
               console.log(error);
            });
      },
      (err) => {
         ShowAlert(err.settings.message, textConst.error);
         callback();
      }
   );
};

export const createProfileDob = (user, dob, isCompare, fullName, callback, success, error) => {
   if (!nameRegex.test(fullName)) {
      ShowAlert(textConst.fullname, textConst.error);
      return;
   } else if (dob === null) {
      ShowAlert(textConst.dob, textConst.error);
      return;
   }
   callback();
   let sendOtp = {};
   sendOtp = {
      action: socketActions.auth,
      subAction: socketSubActions.updatePatientDOB,
      userId: user.userId,
      name: fullName,
      dob: formatDate(dob, false),
      isCompare: isCompare,
   };

   callSocket(
      sendOtp,
      (res) => {
         success();
         callback();
      },
      (err) => {
         error(err);
      }
   );
};

export const UpdateDobAndLogin = (user, dob, dateNew, isCompare, fullName, callback, success, error) => {
   const formattedDob = formatDate(dob);
   const isDateSame = formattedDob === formatDate(dateNew);
   if (dateNew !== null && !isDateSame) {
      createProfileDob(user, dateNew, isCompare, fullName, callback, success, error);
      return;
   }
   if (dateNew === null) {
      createProfileDob(user, dob, isCompare, fullName, callback, success, error);
   }

   if (isDateSame) {
      showSwal(
         "",
         loginConst.patientOnboard.sure + " " + getDobFormat(formatDate(dob)),
         () => {
            createProfileDob(user, dob, isCompare, fullName, callback, success, error);
         },
         textLabels.save
      );
   }
   return;
};
